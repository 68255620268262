import {Delete, get, post, put} from "@/api/axios";

// /web/base/app/list get
export const list = params => get('/web/base/app/list',params)

    // /web/base/app/add post
export const add = params => post('/web/base/app/add',params)

    // /web/base/app/delete/{id} delete
export const deleteNew = params => Delete('/web/base/app/delete/' + params.id, '')

    // /web/base/app/view/{id} get
export const view = params => get('/web/base/app/view/' + params.id, '')

    // /web/base/app/update put
export const update = params => put('/web/base/app/update',params)