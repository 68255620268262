<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="`${form.id ? '编辑' : '新增'}`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="35%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 :upload-error="uploadError"
                 :upload-delete="uploadDelete"
                 :upload-after="uploadAfter"
                 :upload-before="uploadBefore"
                 @submit="submit">
        <template slot="jumpType">
          <el-radio-group v-model="form.jumpType" @change="handleJumpType">
            <el-radio :label="1">跳转内部</el-radio>
            <el-radio :label="2">跳转外部</el-radio>
          </el-radio-group>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, add, view, update, deleteNew } from "@/api/base/app";
import { type } from './json.js'
export default {
  name: "index",
  data(){
    return{
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: 'APP金刚区设置',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号',
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '金刚区名称',
            prop: 'name'
          },
          {
            label: 'icon',
            prop: 'icon',
            type: 'img'
          },
          {
            label: '排序',
            prop: 'sort'
          },
          {
            label: '状态',
            prop: 'state',
            type: 'radio',
            dicData: [
              { label: '显示', value: 1 },
              { label: '隐藏', value: 2 }
            ]
          }
        ]
      },


      dialogVisible: false,
      form: {
        id: '',
        name: '',
        icon: '',
        jumpType: 1,
        type: '',
        url: '',
        state: 2,
        sort: ""
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: '金刚区名称',
            prop: 'name',
            maxlength: 6,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "最多输入6个字",
              trigger: "blur"
            }]
          },
          {
            label: 'icon',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传1张，限制：20M，建议尺寸：88*88',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传轮播图",
              trigger: "blur"
            }]
          },
          {
            label: '跳转类型',
            prop: 'jumpType',
            span: 24,
            rules: [{
              required: true,
              message: "请选择跳转类型",
              trigger: "blur"
            }]
          },
          {
            label: '跳转内部链接',
            prop: 'type',
            display: true,
            span: 24,
            type: 'select',
            // 内部跳转类型：1-优质房源-新房；2-优质房源-二手房房；3-优质房源-办公租赁；4-发布房源；5-经纪人入口；6-地图找房；7-帮我找房；8-我的发布；9-新房流程；10-线上签约；11-我的费用；12-交易进程；13-我要看房
            dicData: type,
            rules: [{
              required: true,
              message: "请选择跳转内部链接",
              trigger: "blur"
            }]
          },
          {
            label: '跳转链接',
            prop: 'url',
            maxlength: 100,
            span: 24,
            display: false,
            rules: [{
              required: true,
              message: "请输入跳转链接",
              trigger: "blur"
            }]
          },
          {
            label: '状态',
            prop: 'state',
            type: 'radio',
            dicData: [
              {
                label: '显示',
                value: 1
              },
              {
                label: '隐藏',
                value: 2
              }
            ],
            span: 24,
            rules: [{
              required: true,
              message: "请选择状态",
              trigger: "blur"
            }]
          },
          {
            label: '排序值',
            prop: 'sort',
            type: 'number',
            span: 24,
            maxRows: 999999,
            minRows: 1,
            rules: [{
              required: true,
              message: "请输入排序值",
              trigger: "blur"
            }]
          },
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize
      }).then(res => {
        // console.log(res);
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 新增
    handleCreate(){
      // console.log('新增')
      this.dialogVisible = true;
    },
    // 编辑
    async handleEdit(row){
      // console.log('编辑',row);
      const { data } = await view({
        id: row.id
      })
      // console.log(data)
      this.form = data;
      this.handleJumpType(data.jumpType)
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此数据?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 选择跳转类型
    handleJumpType(e){
      // console.log(e)
      const type = this.findObject(this.formOption.column, 'type');
      const url = this.findObject(this.formOption.column, 'url');
      if (e === 1) {
        type.display = true
        url.display = false
      }else{
        type.display = false
        url.display = true
      }
    },
    // 保存
    submit(form,done){
      // console.log(form)
      if(form.id){
        update(form).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功');
            this.dialogVisible = false;
            this.handleJumpType(1)
            setTimeout(()=>{
              this.resetForm();
            },500)
            this.onLoad();
          }else{
            this.$message.warning(res.msg);
            done();
          }
        }).catch(()=> done())
      }else{
        add(form).then(res => {
          if(res.code === 200){
            this.$message.success('新增成功');
            this.dialogVisible = false;
            this.handleJumpType(1)
            setTimeout(()=>{
              this.resetForm();
            },500)
            this.onLoad();
          }else{
            this.$message.warning(res.msg);
            done();
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹框
    handleClose(){
      this.dialogVisible = false;
      this.handleJumpType(1)
      setTimeout(()=>{
        this.resetForm();
      },500)
    },
    resetForm(){
      this.form = {
        id: '',
        name: '',
        icon: '',
        jumpType: 1,
        type: '',
        url: '',
        state: '',
        sort: ''
      }
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        column.propsHttp.name = res.path;
      }
    }
  }
}
</script>

<style scoped>

</style>